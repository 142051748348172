<template>
  <v-card outlined>
    <v-alert
      color="amber lighten-5"
      class="
        d-flex
        align-content-center
        justify-content-center
        text-uppercase
        lien_parente
      "
    >
      {{ userLienParente }}
    </v-alert>
    <v-list-item three-line>
      <div class="mt-4">
        <div class="subtitle-1 mt-1">{{ userFullName }}</div>

        <div class="mt-3">
          <v-chip small class="mb-2" color="gray accent-4" outlined>
            <v-icon small left>mdi-phone</v-icon>
            {{ userTelephone }}
          </v-chip>
        </div>

        <div class="">
          <v-chip small class="" color="gray accent-4" outlined>
            <v-icon small left>mdi-at</v-icon>
            {{ userEmail }}
          </v-chip>
        </div>
      </div>
    </v-list-item>

    <v-card-actions class="text-right" v-if="edit">
      <v-spacer></v-spacer>
      <v-btn @click="modalConfirmIsOpen = true" icon color="red">
        <v-icon>mdi-delete-forever</v-icon>
      </v-btn>
      <v-btn @click="modalIsOpen = true" icon color="blue-primary">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-card-actions>

    <ModalEditUserPap
      v-if="modalIsOpen"
      :isOpen="modalIsOpen"
      :context="{ ...context, userPAP: userPAP }"
      @onClose="modalIsOpen = !modalIsOpen"
      @onUpdated="onUpdated"
    />
    <ModalConfirm
      :showDialog="modalConfirmIsOpen"
      @answerYes="deleteUser"
      @answerNo="modalConfirmIsOpen = false"
    />
  </v-card>
</template>

<script>
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import { mapActions } from "vuex";

export default {
  components: {
    ModalEditUserPap: () =>
      import(
        /* modalEditUserPAP */ "@/components/utils/modals/Pap/PAP__Modal.vue"
      ),
    ModalConfirm: () =>
      import(/* modalConfirm */ "@/components/utils/modals/yes-no.vue"),
  },

  data: () => ({
    modalIsOpen: false,
    userPAP: {},
    modalConfirmIsOpen: false,
  }),

  created() {
    this.userPAP = this.context.userPAP;
  },

  computed: {
    userLienParente() {
      return this.userPAP.Lien_Parente ?? "Aucun lien de parenté";
    },

    userTelephone() {
      return this.userPAP.Tel_Mobile;
    },

    userEmail() {
      return this.userPAP.Email;
    },

    userFullName() {
      return this.userPAP.Nom + " " + this.userPAP.Prenom;
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["deleteUtilisateurPAP"]),

    onUpdated() {
      this.modalIsOpen = false;
      this.$emit("onUpdated");
    },

    async deleteUser() {
      try {
        await this.deleteUtilisateurPAP({
          ...this.context,
          ID_Pap: this.userPAP.id,
        });
        success_notification("Utilisateur supprimé");
        this.$emit("onDeleted", this.userPAP);
      } catch (e) {
        error_notification();
      }
    },
  },

  props: {
    context: {
      type: Object,
    },

    edit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.lien_parente {
  font-family: "Roboto";
  font-size: 12px !important;
}
</style>
